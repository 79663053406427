import LayoutProductPage from '../../components/layouts/ProductPage';
import * as styleProduct from '../../styles/pages/productIndex.module.scss';
import * as style from '../../styles/pages/product.module.scss';
import * as buttonStyle from '../../styles/button.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import IconFeatureListOfProduct from '../../components/IconFeatureListOfProduct';
import { Link } from 'gatsby';
import { PageContractsMetadata } from '../../data/page-contract';
import Gallery from '../../components/Gallery';

export default function contracts() {
  const { priceListUrlID, produkt, productFeatures, featuresIcons, technicalFeatures, gallery } =
    PageContractsMetadata();
  console.log(gallery);
  const styleName = produkt.barva;
  return (
    <section className={style[styleName]}>
      <Helmet title={produkt?.nazev}> </Helmet>
      <LayoutProductPage
        header="Produkt"
        subHeader={`TALXIS ${produkt?.nazev}`}
        color={styleName}
        backLink="/products"
        copy={produkt?.copy}
      >
        <section className={classnames(styleProduct.productDetail, styleProduct[styleName])}>
          <section className={styleProduct.product}>
            <div className={classnames(gstyle.center, styleProduct.center)}>
              <div
                className={classnames(style.image)}
                style={{ backgroundImage: `url(${produkt.image.sourceUrl})` }}
              ></div>
              <div className={styleProduct.copy}>
                <h1>
                  <img src={produkt?.logo.sourceUrl} alt="" />
                  TALXIS <br />
                  {produkt.nazev}
                </h1>
                <p>{produkt.hlavniPopis}</p>
                <p>{produkt.popis}</p>
              </div>
            </div>
          </section>
          <section className={style.featureList}>
            <div className={classnames(gstyle.center, styleProduct.center)}>
              <hr />
              <ul>
                {productFeatures?.features?.map((feature: any, i: number) => (
                  <li>
                    <img src="\assets\icons\arrowRight.png" alt="" key={i} />
                    <h1>{feature.header}</h1>
                    <p>{feature.description}</p>
                  </li>
                ))}
              </ul>
              <hr />
            </div>
          </section>

          <section className={style.iconProductFeaturesList}>
            <div className={classnames(gstyle.center, styleProduct.center)}>
              <IconFeatureListOfProduct
                featuresIcons={featuresIcons?.feature}
              ></IconFeatureListOfProduct>
              {priceListUrlID?.url ? (
                <Link
                  to={'/prices#' + priceListUrlID?.url}
                  className={classnames(
                    buttonStyle.button,
                    buttonStyle[styleName ?? 'orange'],
                    buttonStyle.right,
                  )}
                >
                  Zobrazit ceníky
                </Link>
              ) : null}
            </div>
          </section>

          {technicalFeatures?.list?.length > 0 && (
            <section className={classnames(style.featureList, style.featureListv2)}>
              <div className={classnames(gstyle.center, styleProduct.center)}>
                <h1>Technické vlastnosti</h1>
                <hr />
                <ul>
                  {technicalFeatures?.list.map((feature: any) => (
                    <li>
                      <img src="\assets\icons\arrowRight.png" alt="" />
                      <h1>{feature.header}</h1>
                    </li>
                  ))}
                </ul>
                <hr />
              </div>
            </section>
          )}

          {gallery?.images?.length > 0 && (
            <section className={style.imageGallery}>
              <div className={classnames(gstyle.center, styleProduct.center)}>
                <Gallery images={gallery?.images}></Gallery>
              </div>
            </section>
          )}
        </section>
      </LayoutProductPage>
      <section className={styleProduct.footerSection}>
        <Footer color={styleName}></Footer>
      </section>
    </section>
  );
}
