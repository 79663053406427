import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePageContractsdataCs } from '../hooks/cs/page-contracts.metadata';
import { usePageContractsdataEn } from '../hooks/en/page-contracts.metadata';

export function PageContractsMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePageContractsdataCs();
  } else {
    data = usePageContractsdataEn();
  }

  return data;
}
